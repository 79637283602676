import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Search = () => {
  return (
    <div className="flex items-center justify-end me-6 w-50">
      <input className="opacity-0" />
      <button className="outline-none bg-transparent flex items-center">
        <StaticImage
          src="../../images/icons/scope.svg"
          placeholder="none"
          width={24}
          height={24}
          layout="fixed"
          quality={100}
          alt="Scope icon"
        />
      </button>
    </div>
  );
};

export default Search;
